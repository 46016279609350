var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { isOpen: !!_vm.component, title: _vm.title, size: _vm.size },
      on: { "on-close": _vm.handleModalClose }
    },
    [
      _c(
        _vm.component,
        _vm._b(
          { tag: "component", on: { "on-close": _vm.handleClose } },
          "component",
          _vm.props,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }