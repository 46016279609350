<template>
  <Modal
    :isOpen="!!component"
    :title="title"
    :size="size"
    @on-close="handleModalClose"
  >
    <component :is="component" @on-close="handleClose" v-bind="props" />
  </Modal>
</template>

<script>
import { Bus } from "../../eventBus";
export default {
  components: {
    Modal: () => import(/* webpackPrefetch: true */ "../common/Modal"),
  },
  data() {
    return {
      component: null,
      title: "",
      size: "sm",
      props: null,
      closeOnClick: true,
    };
  },
  created() {
    Bus.$on(
      "open",
      ({
        component,
        title = "",
        size = "sm",
        props = null,
        closeOnClick = true,
      }) => {
        this.component = component;
        this.title = title;
        this.size = size;
        this.props = props;
        this.closeOnClick = closeOnClick;
      }
    );
    document.addEventListener("keyup", this.handleKeyup);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyup);
  },
  methods: {
    handleModalClose(force = false) {
      if (!this.closeOnClick && !force) return;
      this.handleClose();
    },
    handleClose() {
      this.component = null;
    },
    handleKeyup(e) {
      if (e.keyCode === 27) this.handleClose();
    },
  },
};
</script>
